import {
  Drawer,
  Heading,
  TextLink as PomodoroTextLink,
  Text,
} from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Drawer.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  height: 100%;
  padding: ${({ theme }) => theme.space.regular};
`;

export const Title = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: ${({ theme }) => theme.space.larger};
  text-align: center;

  margin-top: ${({ theme }) => theme.space.larger};
  margin-bottom: ${({ theme }) => theme.space.large};
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.lineHeights.s};
  text-align: center;
`;

export const TextLink = styled(PomodoroTextLink)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};

  margin-top: ${({ theme }) => theme.space.large};
`;
