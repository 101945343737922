import { useAddress } from "@app/domains/address/context";
import { addressAboyeur } from "@app/domains/address/events";
import { useMerchant } from "@app/domains/merchant/context";
import { DeliveryMethod } from "@app/domains/merchant/models";
import { Heading, Text, snackbar } from "@app/domains/shared/design-system";
import NextImage from "next/image";
import { useEffect } from "react";

import * as S from "./styles";

type DeliveryUnavailableProps = {
  onClose: () => void;
  onCloseWithTakeout: () => void;
};

export const DeliveryUnavailable: React.FC<DeliveryUnavailableProps> = ({
  onClose,
  onCloseWithTakeout,
}) => {
  const { merchant } = useMerchant();
  const { setDeliveryMethod } = useAddress();

  useEffect(() => {
    addressAboyeur.events.details.deliveryUnavailable();
  }, []);

  function handleOkButtonClick() {
    addressAboyeur.events.details.deliveryUnavailableOkClick();

    onClose();
  }

  async function handleTakeout() {
    addressAboyeur.events.details.takeout();

    try {
      const takeoutMethodResponse = await merchant.getTakeoutMethod();

      if (!takeoutMethodResponse) {
        return;
      }

      const takeoutMethod = DeliveryMethod.fromApi(takeoutMethodResponse);

      setDeliveryMethod(takeoutMethod);
      onCloseWithTakeout();
    } catch (error) {
      snackbar({
        variant: "error",
        message: "Tente novamente",
      });

      addressAboyeur.events.catch.onError(error as Error);
    }
  }

  return (
    <>
      <S.Body>
        <NextImage src="/icons/missing-location.svg" width={156} height={156} />
        <Heading variant="medium" textAlign="center">
          Entrega indisponível <br /> no momento
        </Heading>

        {merchant.hasTakeoutFeature() ? (
          <Text variant="regular" textAlign="center" color="grayDarkest">
            Infelizmente, estamos sem entrega <br /> no momento. Você pode optar
            por retirar <br /> na loja
          </Text>
        ) : (
          <Text variant="regular" textAlign="center" color="grayDarkest">
            Infelizmente, estamos com poucas <br /> pessoas entregadoras no
            momento. <br />
            Tente novamente em alguns minutos.
          </Text>
        )}
      </S.Body>

      {merchant.hasTakeoutFeature() ? (
        <S.Button onClick={handleTakeout} tabIndex={0}>
          Retirar na loja
        </S.Button>
      ) : (
        <S.Button onClick={handleOkButtonClick} tabIndex={0}>
          Ok, entendi
        </S.Button>
      )}
    </>
  );
};
