import {
  Flex,
  Button as PomodoroButton,
} from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Body = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-top: auto;
  padding: ${({ theme }) => theme.space.small};
`;

export const Button = styled(PomodoroButton)`
  margin: ${({ theme }) => theme.space.regular};

  font-size: ${({ theme }) => theme.fontSizes.s};
  text-align: center;
`;
