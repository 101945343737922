/// <reference types="@types/googlemaps" />
import { addressAboyeur } from "@app/domains/address/events";
import {
  AutocompleteAddress,
  AutocompleteAddresses,
  Coordinate,
} from "@app/domains/address/models";
import {
  AutocompleteSearch,
  snackbar,
} from "@app/domains/shared/design-system";
import { getPlacePredictions } from "@app/domains/shared/google-maps-utils";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import * as S from "./styles";

const optionsLabel = (
  addresses: google.maps.places.AutocompletePrediction[],
) => {
  return addresses.map((address) => address.description);
};

type SearchProps = {
  placeholder?: string;
  position: Coordinate | undefined;
  onAutocompleteAddress: (autocompleteAddress: AutocompleteAddress) => void;
};

export const Search: React.VFC<SearchProps> = ({
  position,
  placeholder,
  onAutocompleteAddress,
}) => {
  const [autocompleteAddresses, setAutocompleteAddresses] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const handleSearch = useDebouncedCallback(async (searchText: string) => {
    if (!searchText || searchText === "") {
      setAutocompleteAddresses([]);
      return;
    }

    if (!position) {
      getPlacePredictions(searchText, {
        onFailCallback: () => setAutocompleteAddresses([]),
        onSuccessCallback: (
          response: google.maps.places.AutocompletePrediction[] | null,
        ) => {
          if (!response) return;
          setAutocompleteAddresses(response);
        },
      });
      return;
    }

    getPlacePredictions(
      searchText,
      {
        onFailCallback: () => setAutocompleteAddresses([]),
        onSuccessCallback: (
          response: google.maps.places.AutocompletePrediction[] | null,
        ) => {
          if (!response) return;
          setAutocompleteAddresses(response);
        },
      },
      {
        lat: position.latitude,
        lng: position.longitude,
      },
    );
  });

  async function handleOnSelectOption(option: string) {
    if (!option) {
      return;
    }

    try {
      const rawByGeocodeAddresses =
        await AutocompleteAddresses.getAddressesByGeocode(option);
      const geocodeAddresses = AutocompleteAddresses.fromApi(
        rawByGeocodeAddresses,
      );

      if (!geocodeAddresses || geocodeAddresses.list.length === 0) {
        addressAboyeur.events.details.searchForAddressError(
          "no_geocode_addresses_found",
        );
        return;
      }

      addressAboyeur.events.details.searchForAddress();
      onAutocompleteAddress(geocodeAddresses.list[0]);
    } catch (error: any) {
      const message =
        error.message || "Algo deu errado!, tente novamente em alguns segundos";

      addressAboyeur.events.catch.onError(error.message);
      snackbar({ variant: "error", message });
    }
  }

  return (
    <S.Search>
      <AutocompleteSearch
        name="address"
        placeholder={placeholder ?? "Buscar endereço e número"}
        onSearch={handleSearch}
        options={optionsLabel(autocompleteAddresses)}
        onSelectOption={handleOnSelectOption}
      />
    </S.Search>
  );
};
