import {
  AutocompleteAddress,
  AutocompleteAddresses,
  Coordinate,
} from "@app/domains/address/models";
import dynamic from "next/dynamic";
import { useState } from "react";
import SVG from "react-inlinesvg";
import { MapContainerProps, TileLayerProps } from "react-leaflet";

import { PinDraggerProps } from "./PinDragger";
import * as S from "./styles";

const MapContainer = dynamic<MapContainerProps>(
  () => import("react-leaflet").then((res) => res.MapContainer),
  {
    ssr: false,
  },
);
const TileLayer = dynamic<TileLayerProps>(
  () => import("react-leaflet").then((res) => res.TileLayer),
  { ssr: false },
);
const PinDragger = dynamic<PinDraggerProps>(
  () => import("./PinDragger").then((res) => res.PinDragger),
  { ssr: false },
);

type PinProps = {
  onChangePinLocation: (address: AutocompleteAddress) => void;
  autocompleteAddress: AutocompleteAddress;
};

export const Pin: React.FC<PinProps> = ({
  onChangePinLocation,
  autocompleteAddress,
}) => {
  const [position, setPosition] = useState<Coordinate>(
    autocompleteAddress.coordinates,
  );

  const submitUpdatedAutocompleteAddress = async () => {
    const isSamePosition = position == autocompleteAddress.coordinates;

    if (isSamePosition) {
      onChangePinLocation(autocompleteAddress);
      return;
    }

    const addresses = AutocompleteAddresses.fromApi(
      await AutocompleteAddresses.getAddressesByReverseGeocode(
        position.latitude,
        position.longitude,
      ),
    );

    if (!addresses || !addresses.list) {
      return;
    }

    onChangePinLocation(addresses.list[0]);
  };

  return (
    <S.WrapperContainer>
      <S.Wrapper>
        <S.Body>
          <S.Container>
            <S.Pin>
              <S.PinWrapper>
                <S.PinInfo>
                  <S.PinTitle>Você está aqui?</S.PinTitle>
                  <S.PinSubtitle>Ajuste sua localização</S.PinSubtitle>
                </S.PinInfo>
                <S.PinArrow />

                <SVG src="/images/address/map-pin.svg" />
              </S.PinWrapper>
            </S.Pin>

            <MapContainer
              style={{ width: "100%", height: "100%" }}
              center={[position.latitude, position.longitude]}
              zoom={18}
              scrollWheelZoom={false}
              zoomControl={false}
            >
              <TileLayer
                attribution="Google Maps"
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
              />

              <PinDragger onChangePinLocation={setPosition} />
            </MapContainer>
          </S.Container>
        </S.Body>
      </S.Wrapper>
      <S.Footer>
        <S.Button onClick={() => submitUpdatedAutocompleteAddress()}>
          Confirmar localização
        </S.Button>
      </S.Footer>
    </S.WrapperContainer>
  );
};
