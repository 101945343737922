import { Flex, Icon as PomodoroIcon } from "@app/domains/shared/design-system";
import styled from "styled-components";

type Themed = { theme: any };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space.larger};
  margin-bottom: ${({ theme }) => theme.space.regular};
`;

export const Loading = styled(Flex)`
  gap: 10px;
  border-radius: 12px;
  margin-top: ${({ theme }) => theme.space.small};
  padding: ${({ theme }) => theme.space.regular};
  border: solid 1px ${({ theme }) => theme.colors.gray};

  @media (${belowDesktop}) {
    padding: ${({ theme }) => theme.space.small};
  }
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.gray};
`;
