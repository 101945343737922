import { groceriesApiBffURL } from "@app/domains/shared/config";
import axios, { AxiosInstance } from "axios";
import {
  ChainResponse,
  ClosestMerchantsResponse,
  LinkChain,
  MerchantResponse,
} from "./types";
import { capitalizeFirstLetter } from "@app/domains/shared/string-utils";

const nagumoTakeoutUuids = [
  "d2a45a7b-10e5-47b8-af4b-f184645a0bae",
  "3e2e0bf4-d1c6-4b2b-bc57-211a71c115d5",
  "bdfd9044-898a-4fe2-a411-b60329d971b6",
  "739a762f-f0af-457d-add3-fd17e647e56",
  "dece5509-c600-4825-934c-8c30c5d716a8",
  "789bcefa-94cc-4804-9bcd-2e5096528cac",
  "18d4725a-41ae-4342-be31-f9a9b6d2716f",
  "618b4d08-9394-4789-b525-9e636fd91c08",
  "466335f7-6554-413c-bf91-d3147d439979",
  "7c501536-b6b5-4c16-a4aa-f064e87122fb",
  "74b2f698-cffc-4a38-b8ce-0407f8d98de3",
  "25ec23be-1d47-449f-9328-f9e18663dae8",
  "739a762f-f0af-457d-add3-fd17e647e568",
];

export class Chain {
  static groceriesApiBffClient: AxiosInstance;

  static initGroceriesApiBffClient(): void {
    Chain.groceriesApiBffClient = axios.create({
      baseURL: groceriesApiBffURL,
    });
  }

  static async getAllChainSlugs() {
    if (!Chain.groceriesApiBffClient) {
      Chain.initGroceriesApiBffClient();
    }

    const { data } =
      await Chain.groceriesApiBffClient.get<string[]>(`/chain/all-slugs`);

    return data;
  }

  static async getChainByHost(host: string) {
    if (!Chain.groceriesApiBffClient) {
      Chain.initGroceriesApiBffClient();
    }

    const { data } = await Chain.groceriesApiBffClient.get<ChainResponse>(
      `/chain/by-host`,
      {
        params: {
          host: host,
        },
      },
    );

    data.merchants =
      data.merchants?.map((merchant) => {
        if (nagumoTakeoutUuids.includes(merchant.id)) {
          return {
            ...merchant,
            features: merchant.features.filter(
              (feature) => feature !== "DELIVERY",
            ),
          };
        }
        return merchant;
      }) ?? [];

    return data;
  }

  static async getChainBySlug(slug: string) {
    if (!Chain.groceriesApiBffClient) {
      Chain.initGroceriesApiBffClient();
    }

    function isValidSlug(slug: string) {
      const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
      return slugRegex.test(slug);
    }

    if (!isValidSlug(slug)) {
      throw new Error("Invalid slug");
    }

    const { data } = await Chain.groceriesApiBffClient.get<ChainResponse>(
      `/chain/by-slug`,
      {
        params: {
          slug: slug,
        },
      },
    );

    data.merchants =
      data.merchants?.map((merchant) => {
        if (nagumoTakeoutUuids.includes(merchant.id)) {
          return {
            ...merchant,
            features: merchant.features.filter(
              (feature) => feature !== "DELIVERY",
            ),
          };
        }
        return merchant;
      }) ?? [];

    return data;
  }

  static async getClosestMerchants(
    name: string,
    latitude: number,
    longitude: number,
  ) {
    if (!Chain.groceriesApiBffClient) {
      Chain.initGroceriesApiBffClient();
    }

    const { data } =
      await Chain.groceriesApiBffClient.get<ClosestMerchantsResponse>(
        `/v2/merchant/chain/${name}`,
        {
          params: {
            lat: latitude,
            long: longitude,
          },
        },
      );

    return data;
  }

  static fromApi(rawMerchantChain: ChainResponse): Chain {
    return new Chain(
      rawMerchantChain.id,
      rawMerchantChain.name ?? capitalizeFirstLetter(rawMerchantChain.slug),
      rawMerchantChain.slug,
      rawMerchantChain.logoUrl,
      rawMerchantChain.primaryColor,
      rawMerchantChain.bannerUrl,
      rawMerchantChain.gaId,
      rawMerchantChain.redirectMerchant,
      rawMerchantChain.links,
      rawMerchantChain.merchants,
    );
  }

  constructor(
    public uuid: string,
    public name: string,
    public slug: string,
    public logoUrl: string,
    public primaryColor: string,
    public banner?: string,
    public gaId?: string,
    public redirectMerchant?: string,
    public links?: LinkChain[],
    public merchants?: MerchantResponse[],
  ) {}

  async getClosestMerchant(latitude: number, longitude: number) {
    const closestMerchants = await Chain.getClosestMerchants(
      this.slug,
      latitude,
      longitude,
    );

    if (!closestMerchants?.merchants.length) return;

    return closestMerchants.merchants.find((merchant) => Boolean(merchant.id));
  }

  getSocialMediaLinks() {
    return this.links?.filter((link) => link.type === "SOCIAL_MEDIA") || [];
  }

  getInstitutionalLinks() {
    return this.links?.filter((link) => link.type === "INSTITUTIONAL") || [];
  }

  hasCustomizedSettings() {
    return Boolean(this.banner);
  }
}
