/// <reference types="@types/googlemaps" />
import { Loader } from "@googlemaps/js-api-loader";
import { ENVS } from "@app/domains/shared/config";
import invoke from "lodash/invoke";

const googleMapsLoader = new Loader({
  apiKey: ENVS.GOOGLE_MAPS_API_KEY,
  libraries: ["places", "drawing", "geometry"],
});

export async function getPlacePredictions(
  query = "",
  callbacks = {},
  position = { lat: 1, lng: 1 },
  radius = 1000,
) {
  if (!("google" in global)) {
    await googleMapsLoader.importLibrary("maps");
  }

  if (google?.maps?.places) {
    const autocomplete = new google.maps.places.AutocompleteService();
    if (!autocomplete) {
      console.log("Autocomplete API was not found");
      return;
    }

    if (autocomplete && query !== "" && query.replace(" ", "").length) {
      invoke(callbacks, "onStartCallback", query);
      autocomplete.getPlacePredictions(
        {
          input: query,
          componentRestrictions: { country: ["BR"] },
          radius: position ? radius : undefined,
          location: position ? new google.maps.LatLng(position) : undefined,
        },
        (response: any, status: any) => {
          if (status === "ZERO_RESULTS") {
            invoke(callbacks, "onSuccessCallback", []);
            return;
          }

          if (status !== "OK") {
            console.log(
              `Autocomplete response status '${status}' not expected`,
            );
          }

          invoke(callbacks, "onSuccessCallback", response);
        },
      );
      return;
    }
  }
  invoke(callbacks, "onSuccessCallback", []);
  return;
}
