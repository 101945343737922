import { Aboyeur } from "@ifood/aboyeur";
import { getMerchantIdFromUrl } from "@app/domains/shared/url-utils";

const chainRevision = 0;

export const chainAboyeur = new Aboyeur("chain", {
  catch: {
    /**
     * @when An unexpected error occurs during a chain flow
     */
    onError: (error: Error) => ({
      label: "error",
      metadata: { error: error.message, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  search: {
    /**
     * @when is searching the closest merchant
     */
    closestMerchant: () => ({
      label: "search_merchant",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when When a merchant is founded
     */
    merchantFounded: (merchant_id: string) => ({
      label: "merchant_founded",
      metadata: { merchant_id },
    }),
    /**
     * @when When user click to use my location
     */
    useMyLocation: () => ({
      label: "use_my_location",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when When a merchant is not founded
     */
    merchantNotFounded: () => ({ label: "merchant_not_founded" }),
  },
}).withMetadata({
  revision: chainRevision,
});
