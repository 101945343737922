import "leaflet/dist/leaflet.css";

import {
  Drawer,
  Flex,
  Button as PomodoroButton,
  Text,
} from "@app/domains/shared/design-system";
import styled from "styled-components";

export const WrapperContainer = styled(Flex)`
  display: flex;
  height: 60vh;
`;

export const Wrapper = styled(Flex)`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  overflow-y: auto;

  border-radius: 6px;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
`;

export const Body = styled(Drawer.Body)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Footer = styled(Drawer.Footer)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 401;

  padding-left: ${({ theme }) => theme.space.regular};
  padding-right: ${({ theme }) => theme.space.regular};
  padding-bottom: ${({ theme }) => theme.space.large};
  padding-top: ${({ theme }) => theme.space.large};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`;

export const Pin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  position: absolute;

  z-index: 401;
  pointer-events: none;
`;

export const PinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.24));

  position: relative;
  transform: translate(0, -50%);
  z-index: 401;
  pointer-events: none;
`;

export const PinInfo = styled.div`
  text-align: center;

  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.m};

  padding-top: ${({ theme }) => theme.space.regular};
  padding-bottom: ${({ theme }) => theme.space.regular};
  padding-left: ${({ theme }) => theme.space.larger};
  padding-right: ${({ theme }) => theme.space.larger};
`;

export const PinArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;

  border-top: 12px solid ${({ theme }) => theme.colors.white};

  margin-bottom: ${({ theme }) => theme.space.small};
`;

export const PinTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.s};
`;

export const PinSubtitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.grayDarkest};
`;

export const Button = styled(PomodoroButton)`
  width: 100%;

  border-radius: ${({ theme }) => theme.radii.s};
`;
