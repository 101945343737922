import { useAddress } from "@app/domains/address/context";
import { addressAboyeur } from "@app/domains/address/events";
import {
  AutocompleteAddress,
  CustomerAddress,
} from "@app/domains/address/models";
import { useMerchant } from "@app/domains/merchant/context";
import { DeliveryMethod } from "@app/domains/merchant/models";
import { useRouter } from "next/router";
import React, { useState } from "react";

import { Add } from "../Add";
import { Choose } from "../Choose";
import { DeliveryUnavailable } from "../DeliveryUnavailable";
import { NotFound } from "../NotFound/NotFound";
import { Pin } from "../Pin";
import * as S from "./styles";
import { useIsMobile } from "@app/domains/shared/hooks";
import { Z_INDEXES } from "@app/domains/shared/constants";

type Step = "CHOOSE" | "PIN" | "ADD" | "DELIVERY_UNAVAILABLE" | "NOT_FOUND";

type DetailsWithMerchantProps = {
  hidePinStep?: boolean;
};

export const DetailsWithMerchant: React.VFC<DetailsWithMerchantProps> = ({
  hidePinStep,
}) => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const { merchant } = useMerchant();
  const { addressConfig, closeAddress, setAddress, setDeliveryMethod } =
    useAddress();

  const [autocompleteAddress, setAutocompleteAddress] = useState<
    AutocompleteAddress | undefined
  >();
  const [step, setStep] = useState<Step>("CHOOSE");

  const showPinStep = !hidePinStep;

  async function handleAddAddress(address: CustomerAddress) {
    const deliveryMethodResponse = await merchant.getDeliveryMethod(
      address.coordinates.latitude,
      address.coordinates.longitude,
    );

    if (!deliveryMethodResponse) {
      setStep("NOT_FOUND");
      addressAboyeur.events.details.outOfRange();

      return;
    }

    setAddress("MERCHANT", address);

    const deliveryMethod = DeliveryMethod.fromApi(deliveryMethodResponse);
    setDeliveryMethod(deliveryMethod);

    closeAddress();
    setStep("CHOOSE");
  }

  function handleAddAddressError() {
    setStep("DELIVERY_UNAVAILABLE");
  }

  function goToPreviousStep(step: Step) {
    if (step === "PIN") {
      setAutocompleteAddress(undefined);
      setStep("CHOOSE");
      return;
    }
    if (step === "ADD") {
      if (!showPinStep) {
        setAutocompleteAddress(undefined);
        setStep("CHOOSE");
        return;
      }

      setStep("PIN");
      return;
    }
    if (step === "NOT_FOUND") {
      setStep("ADD");
      return;
    }

    closeAddress();
  }

  function handleCloseDeliveryUnavailable() {
    closeAddress();
    addressConfig.onClose?.();
    setStep("CHOOSE");
  }

  function handleCloseDeliveryUnavailableWithTakeout() {
    closeAddress();
    setStep("CHOOSE");
  }

  function handleCloseOutRange() {
    setAutocompleteAddress(undefined);
    setStep("CHOOSE");
  }

  const title = {
    CHOOSE: "Buscar endereço",
    PIN: "Confirme a localização",
    ADD: "Complete seu endereço",
    NOT_FOUND: "Fora da área de entrega",
    DELIVERY_UNAVAILABLE: "Fora da área de entrega",
  };

  return (
    <S.Drawer
      open={true}
      title={title[step]}
      zIndex={Z_INDEXES.ADDRESS_DRAWER}
      onClose={() => goToPreviousStep(step)}
    >
      <S.Wrapper>
        {step === "CHOOSE" && (
          <Choose
            showPreviousAddresses={true}
            onAutocompleteAddress={(address: AutocompleteAddress) => {
              setAutocompleteAddress(address);

              if (!showPinStep) {
                setStep("ADD");
                return;
              }

              setStep("PIN");
            }}
          />
        )}
        {step === "PIN" && (
          <Pin
            onChangePinLocation={(address: AutocompleteAddress) => {
              setAutocompleteAddress(address);
              setStep("ADD");
            }}
            autocompleteAddress={autocompleteAddress as AutocompleteAddress}
          />
        )}
        {step === "ADD" && (
          <Add
            onAddAddress={handleAddAddress}
            onAddAddressError={handleAddAddressError}
            autocompleteAddress={autocompleteAddress as AutocompleteAddress}
            isEditable={!showPinStep}
          />
        )}
        {step === "DELIVERY_UNAVAILABLE" && (
          <DeliveryUnavailable
            onClose={handleCloseDeliveryUnavailable}
            onCloseWithTakeout={handleCloseDeliveryUnavailableWithTakeout}
          />
        )}
        {step === "NOT_FOUND" && (
          <NotFound onChangeAddress={handleCloseOutRange} />
        )}
      </S.Wrapper>
    </S.Drawer>
  );
};
