import { addressAboyeur } from "@app/domains/address/events";
import { CustomerAddress } from "@app/domains/address/models";
import { useAuthentication } from "@app/domains/authentication/context";
import { useMerchant } from "@app/domains/merchant/context";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { AddressItem } from "../AddressItem";
import * as S from "./styles";
import { Text } from "@ifood/pomodoro-components";
import { History } from "@ifood/pomodoro-icons";

export const List: React.VFC = () => {
  const { merchant } = useMerchant();
  const { customer } = useAuthentication();

  const [loading, setLoading] = useState(true);
  const [customerAddresses, setCustomerAddresses] = useState<CustomerAddress[]>(
    [],
  );

  useEffect(() => {
    const getAddresses = async () => {
      if (customer) {
        try {
          const addresses =
            await CustomerAddress.getMaximumThreeLastDifferentAddresses(
              merchant,
            );

          setCustomerAddresses(addresses);
          setLoading(false);
        } catch (e) {
          const error = e as AxiosError;
          addressAboyeur.events.catch.onError(error);
          setLoading(false);
        }
      }
    };

    getAddresses();
  }, [customer, merchant]);

  return customerAddresses.length > 0 || (loading && customer) ? (
    <S.Wrapper>
      <Text fontWeight="500" fontSize="16px" lineHeight="24px" color="black">
        Recentes
      </Text>
      {loading && (
        <S.Loading>
          <S.Icon component={History} size="s" />
          <Text color="gray">Carregando ...</Text>
        </S.Loading>
      )}
      {customerAddresses?.map((address) => (
        <AddressItem key={address.id} address={address} />
      ))}
    </S.Wrapper>
  ) : null;
};
